import {define, html} from 'hybrids';
import {ImageTextSectionElement} from "./types";

export default define<ImageTextSectionElement>({
  tag: 'image-text-section',
  alternating: false,
  render: ({alternating}) => html`
    <div class="${{
    'image-text-section': true,
    'image-text-section--alternating': alternating,
  }}">
        <figure class="image-text-section_image">
            <slot name="image"></slot>
        </figure>
        
        <div class="image-text-section_content">
            <slot></slot>
        </div>
    </div>

    <style>
        .image-text-section {
            display: grid;
            grid-template-columns: repeat(var(--columns), 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: var(--gap);
        }
        
        .image-text-section_image,
        .image-text-section_content {
            grid-row: 1;
            grid-column-end: -1;
        }
        
        .image-text-section_image {
            grid-column-start: 2;
            height: 50vh;
            width: calc(100% + var(--padding_content));
            margin: 0;
            position: relative;
            overflow: hidden;
        }
        
        .image-text-section_image ::slotted(img) {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
        
        .image-text-section_content {
            grid-column-start: 1;
            padding: var(--padding_cards);
            position: relative;
            right: var(--padding_content);
            z-index: 1;
            margin: 120px 0 0;
            background-color: var(--color_background);
        }
        
        .image-text-section--alternating .image-text-section_image {
            grid-column: 1 / span 2;
            position: relative;
            right: var(--padding_content);
        }
        
        .image-text-section--alternating .image-text-section_content {
            left: var(--padding_content);
            right: auto;
        }

        @media screen and (min-width: 769px) {
            .image-text-section {
                align-items: center;
            }
            
            .image-text-section .image-text-section_image,
            .image-text-section .image-text-section_content {
                grid-column-end: span 4;
            }
            
            .image-text-section_image {
                grid-column-start: 5;
                width: 100%;
                height: 100%;
            }
            
            .image-text-section_content {
                grid-column-start: 2;
                margin: var(--spacing-medium) 0;
                right: 0;
            }
            
            .image-text-section--alternating .image-text-section_image {
                grid-column-start: 1;
                right: 0;
            }
            
            .image-text-section--alternating .image-text-section_content {
                grid-column-start: 4;
                left: 0;
            }
        }

        @media screen and (min-width: 1281px) {
            .image-text-section .image-text-section_image,
            .image-text-section .image-text-section_content {
                grid-column-end: span 6;
            }
            
            .image-text-section_image {
                grid-column-start: 7;
            }
            
            .image-text-section_content {
                grid-column-start: 2;
            }
            
            .image-text-section--alternating .image-text-section_image {
                grid-column-start: 1;
            }
            
            .image-text-section--alternating .image-text-section_content {
                grid-column-start: 6;
            }
        }
    </style>
  `
})
import Alpine from 'alpinejs'

const COOKIE_ACCEPTED_VALUE = '1';
const COOKIE_DECLINED_VALUE = '0';

Alpine.data('cookieData', () => ({
  cookieBannerVisible: !('cookie_consent' in localStorage),
  accepted: localStorage.getItem('cookie_consent') === COOKIE_ACCEPTED_VALUE,
  init() {
    this.$watch('accepted', () => {
      localStorage.setItem('cookie_consent', this.accepted ? COOKIE_ACCEPTED_VALUE : COOKIE_DECLINED_VALUE)
    })
  }
}));
import {define, html} from "hybrids";
import {JobDescriptionElement} from "./types";

export default define<JobDescriptionElement>({
  tag: 'job-link',
  jobTitle: '',
  location: 'Herzogenaurach',
  fulltime: true,
  render: ({jobTitle, location, fulltime}) => html`
      <div class="job-link">
          <span class="job-link_title">${jobTitle}</span>

          <span class="job-link_meta">
                <svg width="12.826" height="15.991" viewBox="0 0 12.826 15.991">
                    <path
                            d="M-12792.749-9548.055a5.434,5.434,0,0,1-1.752-4.007,5.426,5.426,0,0,1,5.413-5.438,5.426,5.426,0,0,1,5.413,5.438,5.439,5.439,0,0,1-1.743,4l-3.669,4.062Z"
                            transform="translate(12795.501 9558.5)"
                            fill="none"
                            stroke="#000"
                            stroke-width="2"/>
                </svg>

                ${location}, ${fulltime ? 'Vollzeit' : 'Teilzeit'}
            </span>
      </div>

      <style>
          .job-link {
              display: flex;
              justify-content: space-between;
              align-items: center;
              column-gap: var(--spacing_24px);
              row-gap: 8px;
              width: 100%;
              cursor: pointer;
              background-color: transparent;
              border: 3px solid var(--color_background);
              font-weight: 700;
              color: var(--color_text);
              text-decoration: none;
              font-size: 18px;
              flex-wrap: wrap;
              padding: var(--spacing_12px) var(--spacing_24px);
              box-sizing: border-box;
          }

          @media screen and (min-width: 769px) {
              .job-link {
                  padding: var(--spacing_24px) var(--spacing_36px);
                  font-size: 24px;
                  font-family: var(--font-family_root);
              }
          }

          .job-link:hover {
              border-color: currentColor;
          }

          .job-link:focus {
              border-color: var(--color_primary);
          }

          .job-link_meta {
              display: flex;
              align-items: center;
              gap: var(--spacing_12px);
              font-size: var(--font-size_body);
              font-family: var(--font-family_root);
          }
      </style>
  `
})

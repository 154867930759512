import {define, html} from "hybrids";
import {UiArrowElement} from "./types";

export default define<UiArrowElement>({
  tag: 'ui-arrow',
  left: false,
  width: '3em',
  strokeWidth: '3px',
  render: ({width, strokeWidth, left}: UiArrowElement) => html`
      <div class="${{
          'ui-arrow': true,
          'ui-arrow--left': left
      }}">
          <div class="stroke"></div>

          <svg class="arrow" viewBox="0 0 3 5">
              <path d="M0,0L3,2.5,0,5Z" fill="currentColor"/>
          </svg>
      </div>

      <style>
          .ui-arrow {
              --stroke-width: ${strokeWidth};
              display: flex;
              align-items: center;
              width: ${width};
              max-width: 100%;
          }

          .ui-arrow--left {
              transform: rotate(180deg);
          }

          .stroke {
              flex: 1 0 0;
              height: var(--stroke-width);
              background-color: currentColor;
          }

          .arrow {
              height: calc(5 * var(--stroke-width));
          }

          :host:focus .ui-arrow {
              outline: none;
              color: var(--color_primary);
          }
      </style>
  `
})
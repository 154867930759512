import {define, html} from "hybrids";
import {gsap} from 'gsap';
import {UiButtonElement} from './types';

const animationDefaults = {
  ease: 'power4.out',
  duration: 1,
}

const onMouseOver = (host, event) => {
  if (host._isMouseOver) {
    return
  }

  const rect = event.target.querySelector('rect')

  if (!rect) {
    return
  }

  const totalLength = rect.getTotalLength() + 1

  gsap.fromTo(rect, {
    strokeDasharray: totalLength,
    strokeDashoffset: totalLength
  }, {
    strokeDashoffset: 0,
    ...animationDefaults
  })

  const arrow = host.querySelector('ui-arrow')

  if (arrow) {
    gsap.fromTo(arrow, {
      xPercent: 0
    }, {
      xPercent: 10,
      ...animationDefaults
    })
  }

  host._isMouseOver = true
}

const onMouseOut = (host, event) => {
  if (!host._isMouseOver) {
    return
  }

  const rect = event.target.querySelector('rect')

  if (!rect) {
    return
  }

  try {
    const totalLength = rect.getTotalLength() + 1

    gsap.to(rect, {
      strokeDashoffset: totalLength,
      ease: 'power4.out',
      duration: 1.2,
    })

    const arrow = host.querySelector('ui-arrow')

    if (arrow) {
      gsap.to(arrow, {
        xPercent: 0,
        ...animationDefaults
      })
    }
  } catch (e) {
  } finally {
    host._isMouseOver = false
  }
}

const onClick = (host, event) => {
  if (host.type !== 'submit') {
    return
  }

  const form = host.closest('form');

  if (!form) {
    return;
  }

  event.preventDefault();
  const fakeSubmit = document.createElement('button');
  fakeSubmit.type = 'submit';
  fakeSubmit.style.display = 'none';
  form.appendChild(fakeSubmit);
  fakeSubmit.click();
  fakeSubmit.remove();
}

export default define<UiButtonElement>({
  tag: 'ui-button',
  large: false,
  radio: false,
  cta: false,
  type: 'button',
  render: ({large, radio, cta, type}) => html`
      <button
              onmouseout="${onMouseOut}"
              onmouseover="${onMouseOver}"
              ontouchstart="${onMouseOver}"
              ontouchend="${onMouseOut}"
              onfocus="${onMouseOver}"
              onblur="${onMouseOut}"
              onclick="${onClick}"
              class="${{
                  'ui-button': true,
                  'ui-button--large': large,
                  'ui-button--radio': radio,
                  'ui-button--cta': cta,
              }}"
              type="submit">
          <slot></slot>

          <svg width="100%" height="100%" preserveAspectRatio="none">
              <rect x="1.5px" y="1.5px" fill="none" stroke-width="3" stroke-dasharray="10000"
                    stroke-dashoffset="10000"/>
          </svg>
      </button>

      <style>
          :host {
              --padding: 1em 1.8em;

              display: inline-flex;
          }

          :host[large] {
              display: flex;
          }

          :host-context(a) {
              text-decoration: none;
              color: inherit;
          }

          button {
              flex: 1 0 0;
              display: flex;
              justify-content: var(--align, space-between);
              align-items: center;
              position: relative;
              z-index: 1;
              padding: var(--padding);
              width: 100%;
              margin-bottom: 3px;
              border: none;
              background-color: var(--background-color);
              font: inherit;
              outline: none;
              cursor: pointer;
              color: var(--color_text);
          }

          button::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              box-shadow: inset 0 0 0 3px var(--border-color);
              transition: box-shadow .3s ease-out;
          }

          button > * {
              pointer-events: none;
          }

          svg {
              position: absolute;
              top: 2px;
              left: 2px;
              z-index: -1;
              height: 100%;
          }

          rect {
              width: calc(100% - 3px);
              height: calc(100% - 3px);
              vector-effect: non-scaling-stroke;
              stroke: var(--border-color--hover);
          }

          .ui-button--radio,
          .ui-button--large {
              --border-color: var(--color_background);
              --border-color--hover: currentColor;
              font-weight: bold;
          }

          .ui-button--large {
              font-family: var(--font-family_headlines), sans-serif;
              font-size: var(--font-size_button-large);
              font-weight: 500;
              text-transform: none;
          }

          .ui-button--cta {
              --border-color: var(--color_secondary);
              --border-color--hover: currentColor;
          }

          .ui-button--radio svg,
          .ui-button--large svg {
              top: 0;
              left: 0;
              z-index: 1;
          }

          ::slotted(ui-arrow) {
              margin-left: 0.5em;
          }
      </style>
  `
})
import {define, html} from "hybrids";
import {ClientTestimonialElement} from "./types";

export default define<ClientTestimonialElement>({
    tag: 'client-testimonial',
    name: '',
    subtitle: '',
    imageUrl: '',
    render: () => html`
        <div class="testimonial">
            <figure class="testimonial_image">
                <slot name="image"/>
            </figure>

            <div class="testimonial_content">
                <slot/>
            </div>
        </div>

        <style>
            p,
            figure {
                margin: 0;
            }

            .testimonial {
                display: flex;
                flex-wrap: wrap;
            }

            .testimonial_image {
                flex: 1 0 100%;
            }

            .testimonial_image ::slotted(img) {
                display: block;
                width: 100%;
                height: 220px;
                object-fit: cover;
            }

            .testimonial_name {
                font-weight: bold;
            }

            .testimonial_subtitle {
                display: block;
                font-size: var(--font-size_small);
            }

            .testimonial_content {
                flex: 1 1 100%;
                padding: var(--padding_cards);
                background-color: var(--color_background);
            }

            @media screen and (min-width: 769px) {
                .testimonial {
                    flex-wrap: nowrap;
                }

                .testimonial_image {
                    width: 190px;
                    flex: 0 0 auto;
                    position: relative;
                    z-index: 1;
                }

                .testimonial_content {
                    margin-top: var(--spacing-small);
                    margin-left: calc(-1 * var(--spacing-smaller));
                }
            }
        </style>
    `
  }
)
import '@webcomponents/webcomponentsjs/webcomponents-bundle.js';
import Alpine from 'alpinejs';

import "./scripts/animations";
import "./scripts/contact";
import "./scripts/cookies";

import "./components/ui-arrow";
import "./components/ui-button";
import "./components/ui-slider";
import "./components/client-testimonial";
import "./components/image-text-section";
import "./components/job-link";

// Enable HMR for development
if (process.env.NODE_ENV !== 'production') {
  module.hot!.accept();
}

Alpine.start();
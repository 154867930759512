import {define, html} from "hybrids";
import {UiSliderElement} from "./types";

const updateScrollPosition = (host) => {
  const wrapper = host.shadowRoot.querySelector('.ui-slider_elements-wrapper');
  const elements = wrapper.querySelector('.ui-slider_elements');
  const paddingLeft = elements.offsetLeft + parseFloat(window.getComputedStyle(elements).getPropertyValue('padding-left'));

  wrapper.scrollTo({
    left: host.children[host._index].offsetLeft - paddingLeft,
    behavior: 'smooth'
  })
}

const slideToNext = (host) => {
  host._index = Math.min(host._index + 1, host.children.length - 1) % (host.children.length - 1);

  updateScrollPosition(host)
}

const slideToPrevious = (host) => {
  host._index = Math.max(host._index - 1, 0) % (host.children.length - 1);

  updateScrollPosition(host)
}

const onScroll = (host) => {
  const wrapper = host.shadowRoot.querySelector('.ui-slider_elements-wrapper');

  const visibleChildren = [...host.children].filter(child => !!child.clientWidth);
  const elementOffsets = visibleChildren.map(({offsetLeft}) => offsetLeft);
  const elementsRelativeToScrollPosition = elementOffsets.map(offset => Math.abs(offset - wrapper.scrollLeft));
  const minDistance = Math.min(...elementsRelativeToScrollPosition);

  host._atScrollStart = wrapper.scrollLeft <= 0;
  host._atScrollEnd = wrapper.scrollLeft >= wrapper.scrollWidth - wrapper.clientWidth;

  // @ts-ignore
  host._index = elementsRelativeToScrollPosition.findIndex(distance => distance == minDistance)

  /*clearTimeout(host._scrollDebounceTimeout);
  host._scrollDebounceTimeout = setTimeout(() => {
      updateScrollPosition(host)
  }, 100)*/
}

export default define<UiSliderElement>({
  tag: 'ui-slider',
  _index: 0,
  _atScrollStart: true,
  _atScrollEnd: false,
  centerItems: false,
  onlyMobile: false,
  render: ({onlyMobile, centerItems, _atScrollStart, _atScrollEnd}) => html`
      <div class="${{
          'ui-slider': true,
          'ui-slider--only-mobile': onlyMobile
      }}">
          <div class="ui-slider_elements-wrapper" onscroll="${onScroll}">
              <div class="ui-slider_elements">
                  ${centerItems && html`<span class="ui-slider_center-elements-spacing"></span>`}
                  <slot></slot>
                  ${centerItems && html`<span class="ui-slider_center-elements-spacing"></span>`}
              </div>
          </div>

          <div class="ui-slider_controls">
              <button
                      class="${{
                          'ui-slider_control': true,
                          'ui-slider_control_previous': true,
                          'hidden': _atScrollStart
                      }}"
                      onclick="${slideToPrevious}">
                  <ui-arrow width="2em" left></ui-arrow>
              </button>
              <button
                      class="${{
                          'ui-slider_control': true,
                          'ui-slider_control_next': true,
                          'hidden': _atScrollEnd
                      }}"
                      onclick="${slideToNext}">
                  <ui-arrow></ui-arrow>
              </button>
          </div>
      </div>

      <style>
          * {
              box-sizing: border-box;
          }

          :host {
              --item-columns: 3;
          }

          .ui-slider {
              display: flex;
              flex-direction: column;
              align-items: center;
              overflow: hidden;
          }

          .ui-slider_elements-wrapper {
              display: flex;
              justify-content: center;
              overflow-x: auto;
              overflow-y: hidden;
              width: 100%;
          }

          .ui-slider_controls,
          .ui-slider_elements {
              width: 100%;
              max-width: var(--max-width);
              padding-left: var(--padding_content);
              padding-right: var(--padding_content);
          }

          .ui-slider_elements {
              display: flex;
          }

          .ui-slider_elements::after {
              content: '';
              display: block;
              flex: 0 0 calc(50vw - 50%);
          }

          .ui-slider_controls {
              display: flex;
              justify-content: flex-end;
              margin-top: var(--spacing_24px);
          }

          .ui-slider_control {
              background-color: transparent;
              border: none;
              padding: 0;
              transition-timing-function: var(--transition-easing);
              transition-duration: .3s;
              transition-property: transform, opacity;
          }

          .ui-slider_control_previous:hover {
              transform: translateX(-10px);
          }

          .ui-slider_control_next:hover {
              transform: translateX(10px);
          }

          .ui-slider_control.hidden {
              opacity: 0;
              pointer-events: none;
          }

          .ui-slider_control + .ui-slider_control {
              margin-left: var(--spacing_24px);
          }

          .ui-slider_control:focus {
              color: var(--color_primary);
              outline: none;
          }

          .ui-slider_center-elements-spacing,
          ::slotted(*) {
              flex-grow: 1;
              flex-shrink: 0;
              min-width: 0;
          }

          .ui-slider_center-elements-spacing {
              --empty-columns: calc((var(--columns) - var(--item-columns)) / 2);
              --width-column: calc((100% - var(--gap) * (var(--columns) - 1)) / var(--columns));
              flex-basis: calc(var(--width-column) * var(--empty-columns) + var(--gap) * var(--empty-columns));
          }

          ::slotted(*) {
              --number-els: calc(var(--columns) / var(--item-columns));
              flex-basis: calc(100% / var(--number-els) - (var(--gap) * (var(--number-els) - 1) / var(--number-els)));
              margin-left: var(--gap);
          }

          ::slotted(*:first-child) {
              margin-left: 0;
          }

          @media screen and (min-width: 1281px) {
              .ui-slider--only-mobile .ui-slider_elements {
                  display: grid;
                  grid-template-columns: repeat(var(--columns), 1fr);
                  grid-gap: var(--gap);
              }

              .ui-slider--only-mobile ::slotted(*) {
                  margin-left: 0;
                  grid-column: auto / span var(--item-columns);
              }

              .ui-slider--only-mobile .ui-slider_controls {
                  display: none;
              }
          }
      </style>
  `
})
import {gsap} from "gsap";

type RevealType = 'text' | 'ui' | 'image' | 'footer';

type RevealDataProperties = {
  reveal?: RevealType;
  revealWithParent?: RevealType;
  delay?: number;
  stagger?: number;
}

type RevealableHTMLElement = HTMLElement & {
  dataset: DOMStringMap & RevealDataProperties;
}

type AnimateElement = {
  element: RevealableHTMLElement;
  type: RevealType;
  visible: boolean;
  options?: {
    stagger?: number;
    delay?: number;
  };
}

const animations: Record<RevealType, gsap.TweenVars> = {
  text: {
    from: {
      opacity: 0,
      rotate: 1.8,
      yPercent: 20,
    },
    to: {
      opacity: 1,
      rotate: 0,
      yPercent: 0
    },
  },
  ui: {
    from: {
      opacity: 0,
      yPercent: 20,
    },
    to: {
      opacity: 1,
      yPercent: 0,
    },
  },
  image: {
    from: {
      scale: 1.06,
      opacity: 0,
      yPercent: 25
    },
    to: {
      scale: 1.0,
      opacity: 1,
      yPercent: 0
    }
  },
  footer: {
    from: {
      yPercent: 100
    },
    to: {
      yPercent: 0
    }
  }
}

class ElementRevealer {
  /**
   * Tracks a map of revealable
   * @private
   */
    // @ts-ignore
  private elements = new Map<RevealableHTMLElement, AnimateElement[]>();
  private intersectionObserver;

  constructor() {
    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const elementsToAnimate = this.elements.get(entry.target as RevealableHTMLElement);

          elementsToAnimate.forEach((element) => {
            if (element.visible) {
              return
            }

            element.element.style.opacity = '1'
            ElementRevealer.animateElement(element)
          })
        }
      })
    }, {
      threshold: 0.0,
      rootMargin: '50px'
    })
  }

  private static animateElement(animateElement: AnimateElement) {
    animateElement.visible = true;

    const {element, type, options} = animateElement;
    const {delay, stagger} = options;
    const {from, to} = animations[type] ?? animations['ui'];
    const totalDelay = (delay ?? 0) + (stagger ?? 0);
    const defaults = {
      ease: 'power4.out',
      duration: 1.2,
      delay: totalDelay ?? 0,
      transformOrigin: 'left 120%'
    };

    gsap.fromTo(element, from, {
      ...to,
      ...defaults
    })
  }

  public addElement(element: AnimateElement, trigger: RevealableHTMLElement) {
    trigger = trigger ?? element.element;

    if (!this.elements.has(trigger)) {
      this.elements.set(trigger, [])

      this.intersectionObserver.observe(trigger)
    }

    this.elements.set(trigger, [
      ...this.elements.get(trigger),
      element
    ])

    element.element.style.opacity = '0'
  }
}

const elementRevealer = new ElementRevealer();

gsap.utils.toArray('[data-parent]').forEach((parentElement: RevealableHTMLElement) => {
  const {delay, stagger} = parentElement.dataset;
  const children = parentElement.querySelectorAll('[data-reveal-with-parent]');

  children.forEach((element: RevealableHTMLElement, index: number) => {
    elementRevealer.addElement({
      element,
      type: element.dataset.revealWithParent as RevealType,
      visible: false,
      options: {
        delay: +(element.dataset.delay ?? 0) + +(delay ?? 0),
        stagger: stagger ? index * stagger : undefined
      }
    }, parentElement);
  })
})

gsap.utils.toArray('[data-reveal]').forEach((element: RevealableHTMLElement) => {
  elementRevealer.addElement({
    element,
    type: element.dataset.reveal as RevealType,
    visible: false,
    options: {
      delay: +(element.dataset.delay ?? 0)
    },
  }, element);
})

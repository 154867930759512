import Alpine from 'alpinejs'
import axios from 'axios';

import {ContactFormData, ContactMessage, Service} from "~/src/types/mail";

const allServices: Record<string, Service> = {
  'dacheindeckung': {
    value: 'dacheindeckung',
    text: 'Dacheindeckung',
    header: 'Ich möchte mein Dach eindecken lassen'
  },
  'sanierung': {
    value: 'sanierung',
    text: 'Sanierung',
    header: 'Mein Dach soll saniert werden'
  },
  'reparatur': {
    value: 'reparatur',
    text: 'Reparaturen',
    header: 'Ich möchte mein Dach reparieren lassen'
  },
  'einbauten': {
    value: 'einbauten',
    text: 'Einbauten / Ausbauten',
    header: 'Ich möchte mein Dach erweitern'
  },
  'dachfenster': {
    value: 'dachfenster',
    text: 'Dachfenster',
    header: 'Ich suche neue Dachfenster'
  },
  'solarsysteme': {
    value: 'solarsysteme',
    text: 'Solarsysteme',
    header: 'Ich will Solarpanels einbauen lassen'
  },
  'kranarbeiten': {
    value: 'kranarbeiten',
    text: 'Kranarbeiten',
    header: 'Ich benötige Kranarbeiten'
  },
  'anderes': {
    value: 'anderes',
    text: 'Etwas anderes',
    header: 'Ich brauche etwas bestimmtes'
  }
};

Alpine.data('contactForm', (): ContactFormData => ({
  services: allServices,
  selectedService: null,
  name: '',
  email: '',
  phone: '',
  message: '',
  success: false,
  async sendMail() {
    const {name, email, phone, message, selectedService} = this;
    const request: ContactMessage = {
      name,
      email,
      phone,
      message,
      selectedService
    };

    try {
      axios.post('/api/send-mail', request)

      this.success = true;
      window.scrollTo({top: 0, behavior: 'smooth'});
    } catch (e) {
      alert('Leider ist ein Fehler aufgetreten.')
    }
  }
}));